<template>
  <div>
    <v-container fluid>
      <v-row align="center" class="list mx-auto">
        <v-col cols="6" md="2">
          <v-select
            v-model="pageSize"
            :items="pageSizes"
            label="Items/Página"
            centered
            @change="handlePageSizeChange"
          />
        </v-col>
        <v-col cols="12" md="8">
          <v-pagination
            v-model="page"
            :length="totalPages"
            total-visible="10"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange"
          />
        </v-col>
        <v-col cols="6" md="2">
          <v-btn plain to="/solicitudes/agregar"
            ><v-icon left>mdi-file-plus-outline</v-icon>Nueva SPC</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-card class="mx-auto list" width="95%" tile>
          <v-row>
            <v-card-title>Solicitudes de pedido a compras</v-card-title>
            <v-spacer />
            <v-card-subtitle
              v-text="'Mostrando ítems ' + currentItems + ' de ' + totalItems"
            />
          </v-row>
          <v-data-table
            dense
            :headers="headers"
            :items="oc_s"
            disable-pagination
            :hide-default-footer="true"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-icon
                  :elevation="hover ? 16 : 2"
                  :class="{ 'on-hover': hover }"
                  class="mr-2"
                  @click="newinfsema(item.SPC)"
                >
                  mdi-information-variant
                </v-icon>
              </v-hover>
            </template>
          </v-data-table>
          <v-card-actions v-if="oc_s.length > 0">
            <v-pagination
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            ></v-pagination>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
    <spcDialog ref="newSPC" />
  </div>
</template>

<script>
import eventBus from "../EventBus";
import CostDataService from "../services/CostDataService";
// import spcDlg from "./newspc";
export default {
  name: "ordenesdecompra",
  // components: {
  //   spcDialog: spcDlg,
  // },
  data() {
    return {
      totalPages: 0,
      page: 1,
      pageSize: 100,
      pageSizes: [100, 250, 500],
      currentItems: "",
      totalItems: 0,
      oc_s: [],
      headers: [
        { text: "SPC", value: "SPC", align: "start", sortable: false },
        { text: "F. SPC", value: "F_SPC", width: "120px", sortable: false },
        { text: "ESTADO", value: "ESTADO", align: "start", sortable: false },
        { text: "OC", value: "OC", align: "start", sortable: false },
        {
          text: "F. OC",
          value: "FECHA_OC",
          align: "left",
          width: "120px",
          sortable: false,
        },
        { text: "$ Costo", value: "COSTO", align: "center", sortable: false },
        { text: "Ver", value: "actions", align: "center", sortable: false },
      ],
    };
  },
  methods: {
    getRequestData(page, pageSize, estado, codigo, proveedor, empresa) {
      let data = {};
      if (page) {
        data["page"] = page - 1;
      }
      if (pageSize) {
        data["size"] = pageSize;
      }
      if (empresa) {
        data["empresa"] = empresa;
      } else {
        data["empresa"] = "AGROINDUSTRIAL EL PAICO S.A.";
      }
      if (estado) {
        data["estado"] = estado;
      }
      if (codigo) {
        data["codigo"] = codigo;
      }
      if (proveedor) {
        data["proveedor"] = proveedor;
      }
      return data;
    },
    async getSPC() {
      const data = this.getRequestData(this.page, this.pageSize, this.estado);
      CostDataService.getSPC(data).then((response) => {
        const { spcs, totalPages, currentItems, totalItems } = response.data;
        this.oc_s = spcs.map(this.mapData);
        this.totalPages = totalPages;
        this.currentItems = currentItems;
        this.totalItems = totalItems;
      });
    },
    async newinfsema(id) {
      this.id = id;
      await this.$refs.newSPC.open(id);
      // this.$router.push({ name: "code-details", params: { id: id } });
    },
    handlePageChange(value) {
      this.page = value;
      this.getcompras();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getcompras();
    },
    async editSPC(SPC) {
      await this.$refs.newSPC.open(SPC);
    },
    mapData(docs) {
      let costo = 0;
      docs.ITEMS.forEach((el) => {
        costo = costo + Number(el.COSTO.replace(/\D/g, ""));
      });
      let oc = docs.NUM_OC ? docs.NUM_OC : "-";
      let fecha = docs.FECHA_SPC.toString().split("T")[0];
      let f_oc = docs.FECHA_OC ? docs.FECHA_OC.toString().split("T")[0] : "-";
      return {
        SPC: docs.N_SPC,
        F_SPC: fecha,
        ESTADO: docs.ESTADO_SPC,
        OC: oc,
        FECHA_OC: f_oc,
        COSTO: Intl.NumberFormat("es-CL", {
          style: "currency",
          currency: "CLP",
        }).format(costo),
      };
    },
  },
  mounted() {
    this.getSPC();
    eventBus.$emit("title-select", "Solicitudes de Compra");
  },
};
</script>
